@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

* {
  font-family: Roboto, Arial;
  font-weight: 200;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  font-size: 1em;
}

.root {
  height: 100%;
  width: 80%;
  max-width: 1024px;
  margin: 0 auto;
}

nav {
  height: 3em;
  line-height: 3em;
  border-bottom: 1px solid #dddddd;
}

nav > .ctr {
  align-items: stretch;
  display: flex;
  width: 100%;
}

nav a {
  margin: 0 0 0 1em;
  text-decoration: none;
  color: #000;
}

nav a:visited {
  color: #000;
}

a.active {
  border-bottom: 1px solid #000;
}

a:not(.active):hover {
  border-bottom: 1px #000 dashed;
}

nav a.image-link {
  margin: 0;
}

a.image-link:hover {
  border-bottom: none;
}

nav img.app-logo {
  height: 2em;
  vertical-align: middle;
  margin-left: 0;
}

footer {
  border-top: 1px solid #dddddd;
  height: 2em;
  text-align: center;
  line-height: 2em;
  font-size: 0.8em;
}

footer .social {
  padding: 1em 0 0 0;
}

footer a,
footer a:visited {
  text-decoration: none;
  color: #000;
  font-weight: 300;
}

.banner-container {
  position: relative;
}

.banner-container .info-text-box-full {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(221, 221, 221, 0.5);
  width: 100%;
  height: 100%;
}

.banner-container .info-text-box-full h1 {
  color: #000;
  font-size: 4em;
  font-weight: 300;
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% - 1.5em);
}

.banner-container .info-text-box {
  transition: 0.7s ease;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(221, 221, 221, 0.2);
  width: 45%;
  height: 100%;
  border-right: 1px solid #dddddd;
}

.banner-container .info-text-box:hover {
  background-color: rgba(221, 221, 221, 0.55);
  transition: 0.7s ease;
  width: 100%;
}

.banner-container .info-text-box .info {
  color: #000;
  font-size: 2.5em;
  width: 100%;
  text-align: center;
  position: absolute;
  top: calc(50% - 1.5em);
  opacity: 1;
}

.banner-container .info-text-box .info .details {
  opacity: 0;
  transition: 0.7s ease;
}

.banner-container .info-text-box .info .details {
  font-size: 0.8em;
  font-weight: 300;
}

.banner-container .info-text-box:hover .info .details {
  opacity: 1;
  transition: 0.7s ease;
}

.banner-container .info-text-box:hover .info .prompt {
  display: none;
}

.banner-image {
  max-width: 100%;
  width: 100%;
  display: block;
  height: auto;
}

.prompt {
  animation: 1.5s blink step-end infinite;
}

.info-frame {
  padding: 1em 0;
}

.info-container {
  box-shadow: 1px 1px 5px 1px rgba(221, 221, 221, 0.75);
  -webkit-box-shadow: 1px 1px 5px 1px rgba(221, 221, 221, 0.75);
  -moz-box-shadow: 1px 1px 5px 1px rgba(221, 221, 221, 0.75);
}

.info-frame .info-container .title,
.info-frame .info-container .content {
  padding: 1em;
}

.info-frame .info-container .content .social {
  text-align: center;
}

.info-frame .info-container .content .social a {
  display: inline-block;
  text-decoration: none;
  font-weight: 200;
  min-width: 10%;
  text-align: center;
}

.info-frame .info-container .content .social a svg {
  display: block;
  position: relative;
  margin: 0 auto;
}

.info-frame .info-container .content .social a:hover svg {
  transform: rotate(360deg);
  transition: all 0.7s ease;
}

.info-frame .info-container .content .social a:not(:first-child) {
  padding-left: 1em;
}

.info-frame .info-container .content .social a span {
  opacity: 0.4;
  vertical-align: middle;
  line-height: normal;
  color: #777;
  font-weight: 300;
}

.info-frame .info-container .content .social a:hover span {
  opacity: 1;
  transition: ease-out 1s ease;
  font-weight: 400;
}

.info-frame .info-container .content .social a:hover {
  border: none;
}

.info-frame .info-container .content {
  line-height: 1.7;
}

.info-frame .info-container .title {
  background-color: #dddddd;
  font-weight: 300;
}

.quote-box {
  width: 70%;
  margin: 1em auto;
  position: relative;
}

.quote-box .quote {
  font-size: 2em;
  text-indent: 1em;
  font-style: italic;
  text-align: justify;
}

.quote-box .quote::before {
  content: "\201D";
  position: absolute;
  position: absolute;
  top: -0.25em;
  left: -1.1em;
  font-size: 2em;
  font-family: "Fredoka One", cursive;
}

.quote-box .author {
  text-align: right;
  font-weight: 300;
  font-size: 1.5em;
  padding-top: 0.5em;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 0.7em;
  }

  .banner-container .info-text-box .info {
    font-size: 1em;
  }
}
